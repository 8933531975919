import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';
import {
    AiOutlineAppstore,
    AiOutlineClockCircle,
    AiOutlineControl,
    AiOutlineDollar,
    AiOutlineDollarCircle,
    AiOutlineFile,
    AiOutlineHome,
    AiOutlineNumber,
    AiOutlineTransaction,
    AiOutlineUnorderedList
} from 'react-icons/ai'

import {TfiRulerAlt} from "react-icons/tfi";
import {MdOutlineBusinessCenter} from "react-icons/md";
import {OrderIcon} from "../../../features/orders/images/OrderIcon.tsx";
import {SubscriptionIcon} from "../../../features/subscriptions/images/SubscriptionIcon.tsx";
import {RatePlanIcon} from "../../../features/rate-plans/images/RatePlanIcon.tsx";
import {rbacActions} from "../../../config/rbac/actions.ts";


export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            leftSection: <AiOutlineHome/>,
            rbac: rbacActions.ViewDashboard,
        },
        {
            id: uuidv4(),
            link: '/service-plans',
            label: 'Pricing',
            leftSection: <AiOutlineDollar/>,
            rbac: rbacActions.ListServicePlans,
            defaultOpened: true,
            links: [
                {
                    id: uuidv4(),
                    link: '/service-plans',
                    label: 'Service Plans',
                    leftSection: <AiOutlineTransaction/>,
                    rbac: rbacActions.ListServicePlans,
                },
                {
                    id: uuidv4(),
                    link: '/products',
                    label: 'Products',
                    leftSection: <AiOutlineAppstore/>,
                    rbac: rbacActions.ListProducts,
                },
                {
                    id: uuidv4(),
                    link: '/prices',
                    label: 'Prices',
                    leftSection: <AiOutlineDollarCircle/>,
                    rbac: rbacActions.ListPrices,
                },
                {
                    id: uuidv4(),
                    link: '/rate-plans',
                    label: 'Rate Plans',
                    leftSection: <RatePlanIcon/>,
                    rbac: rbacActions.ListRatePlans,
                },
            ]
        },
        {
            id: uuidv4(),
            link: '/meters',
            label: 'Metering',
            leftSection: <TfiRulerAlt/>,
            rbac: rbacActions.ListMeters,
            defaultOpened: true,
            links: [
                {
                    id: uuidv4(),
                    link: '/meters',
                    label: 'Meters',
                    leftSection: <TfiRulerAlt/>,
                    rbac: rbacActions.ListMeters,
                },
                {
                    id: uuidv4(),
                    link: '/metering-records',
                    label: 'Metering Records',
                    leftSection: <AiOutlineClockCircle/>,
                    rbac: rbacActions.ListMeteringRecords,
                },
                {
                    id: uuidv4(),
                    link: '/usage-records',
                    label: 'Usage Records',
                    leftSection: <AiOutlineFile/>,
                    rbac: rbacActions.ListUsageRecords,
                },
            ]
        },
        {
            id: uuidv4(),
            link: '/customers',
            label: 'Billing',
            leftSection: <AiOutlineNumber/>,
            rbac: rbacActions.ListCustomers,
            defaultOpened: true,
            links: [
                {
                    id: uuidv4(),
                    link: '/billing-accounts',
                    label: 'Billing Accounts',
                    leftSection: <MdOutlineBusinessCenter/>,
                    rbac: rbacActions.ListBillingAccounts,
                },
                {
                    id: uuidv4(),
                    link: '/customers',
                    label: 'Customers',
                    leftSection: <MdOutlineBusinessCenter/>,
                    rbac: rbacActions.ListCustomers,
                },
                {
                    id: uuidv4(),
                    link: '/invoices',
                    label: 'Invoices',
                    leftSection: <AiOutlineUnorderedList/>,
                    rbac: rbacActions.ListInvoices,
                },
                {
                    id: uuidv4(),
                    link: '/orders',
                    label: 'Orders',
                    leftSection: <OrderIcon/>,
                    rbac: rbacActions.ListOrders,
                },
                {
                    id: uuidv4(),
                    link: '/subscriptions',
                    label: 'Subscriptions',
                    leftSection: <SubscriptionIcon/>,
                    rbac: rbacActions.ListSubscriptions,
                },
                {
                    id: uuidv4(),
                    link: '/billing-providers',
                    label: 'Billing Providers',
                    leftSection: <AiOutlineControl/>,
                    rbac: rbacActions.ListBillingProviders,
                },
            ]
        }] as PlatformNavbarMenuItem[];

};

