import {
    getPrice,
    listPrices
} from './queries';
import {
    createPrice,
    deletePrice,
    updatePrice,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreatePrice,
    DeletePrice,
    GetPrice,
    ListPrices,
    Price,
    UpdatePrice
} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceNotifications.tsx";


const client = generateClient();

export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListPrices>>({
                                                                            query: listPrices,
                                                                            variables: {filter: filter},
                                                                        });

        return response.data?.listPrices.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPrice>>({
                                                                          query: getPrice,
                                                                          variables: {id: id},
                                                                      });
        return response.data?.getPrice;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreatePrice>>({
                                                                             query: createPrice,
                                                                             variables: {input: payload},
                                                                         });
        console.debug('in the service response', response);
        notifySuccess('Price created');
        return response.data?.createPrice;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (id: string, record: Price) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePrice>>({
                                                                             query: (updatePrice),
                                                                             variables: {input: record},
                                                                         });
        notifySuccess('Price updated')
        return response.data?.updatePrice;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePrice>>({
                                                                             query: (deletePrice),
                                                                             variables: {input: {id: id}},

                                                                         });
        notifySuccess('Price deleted')
        return response.data?.deletePrice;
    } catch (e) {
        notifyError(e)
    }
};
